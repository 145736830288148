<template>
    <div id="city-amount" class="page-root">
        <div id="chart-container">
            <bar-chart :data="dataList" region="单位"></bar-chart>
        </div>
        <div id="selector-container">
            <el-form class="timeForm" v-model="additionForm" :inline="true" :rules="rules">
                <el-form-item label="时间域">
                    <el-select v-model="additionForm.timeRegion" placeholder="选择时间域">
                        <el-option value="年" />
                        <el-option value="月" />
                        <el-option value="周" />
                    </el-select>
                </el-form-item>
                <el-form-item label="日期" v-if="additionForm.timeRegion!==null">
                    <el-date-picker v-model="additionForm.time" v-if="additionForm.timeRegion=='年'" placeholder="选择年份"
                        type="year"></el-date-picker>
                    <el-date-picker v-if="additionForm.timeRegion=='月'" v-model="additionForm.time" placeholder="选择月份"
                        type="month"></el-date-picker>
                    <el-date-picker v-if="additionForm.timeRegion=='周'" v-model="additionForm.time" placeholder="选择周"
                        type="week"></el-date-picker>
                </el-form-item>
            </el-form>
            <el-form class="areaForm" :inline="true" v-model="areaForm" :rules="rules">
                <el-form-item label="省" v-if="provinceList.length>0">
                    <el-select v-model="areaForm.province" placeholder="选择省" clearable @change="getCity">
                        <el-option v-for="item in provinceList" :key="item[0]" :label="item[1]" :value="item[0]">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="cityOptions.length>0" label="地区">
                    <el-select v-model="areaForm.city" placeholder="选择地区" clearable @change="getCounty">
                        <el-option v-for="item in cityOptions" :key="item[0]" :label="item[1]" :value="item[0]">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="countyOptions.length>0" label="区域">
                    <el-select collapse-tags collapse-tags-tooltip multiple v-model="areaForm.county" placeholder="选择区域" clearable @change="selectCounty">
                        <el-option v-for="item in countyOptions" :key="item[0]" :label="item[1]" :value="item[0]">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="el-form">
                <el-button type="primary" size="small" @click="submit()" :disabled="!validate(areaForm.area,additionForm.time)">查询</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import BarChart from "./component/BarChart.vue"

import { getSubOverview,getAreaOverview } from "network/overview"

export default {
    data() {
        return {
            additionForm: {
                timeRegion: null,
                time: null
            },
            cityOptions: [],
            countyOptions: [],
            areaForm: {
                province: null,
                city: null,
                county: null,
                area: null
            },
            rules: {
                time: [
                    { required: true, message: '时间不能为空', trigger: 'change' }
                ]
            },
            dataList:[]
        }
    },
    computed: {
        userAuth() {
            return this.$store.getters.getUserAuth
        },
        userRole() {
            return this.$store.getters.getUserRole
        },
        provinceList() {
            return this.$store.getters['areas/provinces']
        },
        cityList() {
            return this.$store.getters['areas/cities']
        },
        areaList() {
            return this.$store.getters['areas/areas']
        }

    },
    methods: {
        prepare() {
            if (this.provinceList.length <= 0 || this.provinceList === null) {
                if (this.cityList && this.cityList.length > 0)
                    this.cityOptions = this.cityList
                else
                    this.countyOptions = this.areaList
            }
        },
        getCity(val) {
            this.areaForm.city = ""
            this.cityOptions = []
            this.areaForm.county = ""
            this.countyOptions = []
            if (val === '' || val === undefined)
                return
            this.areaForm.area = val

            this.cityOptions = this.cityList.filter(e => e[0].startsWith(val.substring(0, 2)))
        },
        getCounty(val) {
            this.areaForm.county = ""
            this.countyOptions = []
            if (val === '' || val === null) {
                if (this.areaForm.province !== "" && this.areaForm.province !== null)
                    this.areaForm.area = this.areaForm.province
                return
            }
            this.areaForm.area = val
            this.countyOptions = this.areaList.filter(e => e[0].startsWith(val.substring(0, 4)))
        },
        selectCounty(val) {
            if (val && val.length>0)
                this.areaForm.area = val
            else if (this.areaForm.city !== "" && this.areaForm.city !== null)
                this.areaForm.area = this.areaForm.city
        },
        validate(area,time){
            if (area == undefined || area === "") 
                return false;
            if (time == undefined || (!time instanceof Date)) 
                return false

            return true
        },
        getTimeRegion(time,type){
            let date=new Date(time.getTime())
            switch(type)
            {
                case '年':
                    date.setFullYear(date.getFullYear()+1)
                    break
                case '月':
                    date.setMonth(date.getMonth()+1)
                    break
                case '周':
                    date.setDate(date.getDate()+7)
                    break
                date=null
            }
            console.log(date)
            return date
            
        },
        
        submit() {
            let area = this.areaForm.area
            let time = this.additionForm.time
            let type = this.additionForm.timeRegion
            if(!this.validate(area,time)){
                this.$message.warning("区域、时间不能为空")
                return 
            }
            let end= this.getTimeRegion(time,type)
            if(end==null)
                return
            let cache=this.areaList
            
            if((area instanceof Array)&&area.length>1)
            {
                getAreaOverview(time.getTime(),end.getTime(),area).then(
                    res=>{
                        let array=[]
                        for(let i in res)
                    {
                        
                        let b=cache.find(a=>a[0]==area[i])
                        if(b)
                        array.push([b[1],res[i]['qualified'],res[i]['unqualified'],res[i]['qualified']+res[i]['unqualified']])
                        
                    }this.dataList=array
                    console.log(this.dataList)
                    },e=>{console.log(e)}
                )
                return
            }
            if(!(area instanceof Array))
                area=[area]
            getSubOverview(time.getTime(),end.getTime(),area[0]).then(
                res=>{
                    let array=[]
                    if(area[0].match(/\d{4}00/))
                    for(let [k ,v] of Object.entries(res.data))
                    {
                        let tol=v['qualified']+v['unqualified']
                        let b=this.$store.getters['areas/areaName'](k)
                        if(tol>0)
                        array.push([b,v['qualified'],v['unqualified'],tol])
                        
                    }
                    else
                    for(let [k ,v] of Object.entries(res.data))
                    {
                        
                        array.push([k,v['qualified'],v['unqualified'],v['qualified']+v['unqualified']])
                        
                    }
                    this.dataList=array
                },
                e=>{
                    console.log(e.message)
                }
            )


        }
    },
    mounted() {
        this.prepare()
    },
    components: {
        BarChart
    }
}
</script>
<style lang="less" scoped>
#city-amount {
    width: 90vw;
    height: 86vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;

    #chart-container {
        flex: 0 0 65vh;
        height: 65vh;
        width: calc(100% - 2vw);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12) inset;
        background-color: #071f63;
        border-radius: 8px;

        #bar-chart {
            height: 100%;
            width: 100%;
        }
    }

    #selector-container {
        width: calc(100% - 2vw - 4px);
        flex: 0 0 calc(18vh - 4px);
        height: calc(18vh - 4px);
        border: 2px solid var(--el-border-color-base);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .el-form {
            flex: 0 0 5vh;
            height:5vh;
            width: calc(100% - 2vw);
            padding-left: 2vw;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
    }

}
#selector-container /deep/ .el-form-item__label {
  color: #f2f2f2;
  font-size: 1.5vh;
  line-height: 3vh;
}
//输入框样式
#selector-container /deep/ .el-input__wrapper {
  height: 3.2vh;
  background-color: #0c59b9;
  color: #f2f2f2 !important;
  box-shadow: none;
}
#selector-container /deep/ input {
  color: #f2f2f2;
}
</style>