<template>
  <div id="bar-chart" ref="chart"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  LegendComponent,
  TransformComponent,
} from "echarts/components";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import "echarts/theme/macarons.js";
import "echarts/theme/blue.js";
import "echarts/theme/green.js";
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
]);
const sum = function (params) {
  return "总计:" + (params.data[1] + params.data[2]).toString()
}

export default {
  name: "BarChart",
  computed: {
    mychart() {
      return echarts.init(this.$refs.chart, this.theme);
    },
  },
  watch: {
    data(val) {
      console.log(val);
      let dataUpdate = [[this.region, "合格", "不合格", "总数"]]
      dataUpdate.push(...val)
      this.option.dataset[0].source = dataUpdate
      this.mychart && this.mychart.setOption(this.option);
      console.log('value',this.option.dataset);
    },
    
    region(val){
      this.option.dataset[0].source[0] =  [val, "合格", "不合格", "总数"]
    }
  },

  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    theme: "",
    region:'product'
  },
  data() {
    return {
      option: {
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        legend: { top: 5 },
        dataset: [
          {
            source: [
              [this.region, "合格", "不合格", "总数"]
            ],
          },
        ],
        // dataZoom: [
        //         {
        //             showDetail: false,
        //             backgroundColor: '#071f63',   // 设置 DataView 的背景颜色
                  
        //         }
        //     ],
        tooltip: {},
        toolbox: {
          show: true,
          feature: {
            dataView: {
               backgroundColor:'#071f63',
               textColor:'#f2f2f2',
              readOnly: false,
              optionToContent:function(opt){
              try {
              console.log(opt.dataset[0].source);
              const data = opt.dataset[0].source

                  let table = data.reduce((pre,item)=>{
                     pre += '<tr>'
                     for (var i = 0, l = item.length; i < l; i++) {
                        pre  += '<td>' + item[i] + '</td>'
                    }
                    pre  += '</tr>';
                    return pre
                  },'<table style="color:#f2f2f2;border-color:#f2f2f2; width:100%;text-align:center;border-collapse: collapse;" border><tbody>')
                  console.log(table);
                  table += '</tbody></table>'
                  return table
              } catch (error) {
                console.log(error);
                return '<div>数据异常</div>'
              }
              
             },
             
            },
            magicType: {
              type: ["line", "bar"]
            },
            saveAsImage: {}
          }
        },
        yAxis: {
          max: "maxData",
          type: 'value',
        },
        xAxis: {
          type: "category",
        },
        series: [
          {
            color: ['#0098d9'],
            label: {
              show: true,
            },
            datasetIndex: 0,
            type: "bar",
            barMaxWidth: 30
          },
          {
            color: ['#c12e34'],
            label: {
              show: true,
            },
            datasetIndex: 1,
            type: "bar",
            barMaxWidth: 30
          },
          {
            color: ['#2b821d'],
            label: {
              show: true,
            },
            datasetIndex: 2,
            type: "bar",
            barMaxWidth: 30
          }
        ],
      },
    };
  },
  methods: {
    resizeChart: function () {
      this.mychart.resize();
    },
  },
  mounted() {

    this.option.dataset[0].source.push(...this.data)
    this.mychart && this.mychart.setOption(this.option);
    const resizeObserver = new ResizeObserver(this.resizeChart);
    resizeObserver.observe(this.$el);

  },
};
</script>